.checkbox-slider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  user-select: none;
  .checkbox-switch {
    position: relative;
    display: inline-block;
    width: 3.2rem;
    height: 1.9rem;

    cursor: pointer;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    label.switch {
      display: flex;
      vertical-align: middle;
      justify-content: flex-start;
      position: relative;
      display: inline-block;
      width: 3.2rem;
      height: 1.9rem;
      margin-right: 1.5rem;
      cursor: pointer;

      //   span {
      //     font-size: 1.4rem;
      //     font-weight: 500;
      //   }
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $whiteColor;
      border: 0.1rem solid $primaryColor;
      transition: 0.4s;
      border-radius: 2.4rem;
      &:before {
        position: absolute;
        content: '';
        height: 1.1rem;
        width: 1.1rem;
        border-radius: 2.4rem;
        left: 0.3rem;
        bottom: 0.3rem;
        background-color: $primaryColor;
        transition: 0.25s;
      }
    }

    input:checked + .slider {
      &:before {
        transform: translateX(1.4rem);
      }
    }
  }

  .checkbox-slider-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: $blackColor;
    margin-left: 1.5rem;
    cursor: pointer;
    &.left-label {
      margin-left: 0;
      margin-right: 1.5rem;
    }
    &.double-label {
      color: $paleBlueColor;
    }
    &.active-double-label {
      color: $blackColor;
    }
  }
  &.is-invalid {
    .checkbox-switch {
      .slider {
        background-color: $redColor;
      }
    }
    .checkbox-slider-label {
      color: $redColor;
    }
  }
}
