.button {
  background: $primaryColor;
  border-radius: 44px;
  width: auto;
  width: 25.4rem;
  height: 3.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 0 none;
  padding: 1.4rem 0 1.25rem 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: $whiteColor;
  text-transform: uppercase;
  p {
    padding: 1.4rem 0 1.25rem 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: $whiteColor;
    text-transform: uppercase;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.smallButton {
    height: 1.9rem;
    width: auto;
    background-color: $paleBlueColor;
    padding: 0.5rem 1.5rem 0.4rem 1.5rem;
    font-size: 10px;
    p {
      padding: 0.5rem 1.5rem 0.4rem 1.5rem;
      font-size: 10px;
    }
  }
}
.arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: 0 none;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: $paleBlueColor;
  padding-right: 1rem;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: $paleBlueColor;
    padding-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
