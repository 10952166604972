.dropdown-creator {
  display: inline-block;
  height: fit-content;
  position: relative;
  width: 100%;
  transition: all 0.2s ease-in-out;
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }

  &.isInvalid {
    .input-row {
      input {
        border: 0.1rem solid $redColor;
      }
      label {
        color: $redColor;
      }
    }

    .dropdown-opened {
      border: 0.1rem solid $redColor;
      border-top: none;
    }
  }
  .dropdown-creator-opened {
    input {
      border-radius: 0.4rem 0.4rem 0 0;
    }
  }

  .dropdown-upside-down {
    input {
      border-radius: 0 0 0.4rem 0.4rem;
    }

    input:not(:placeholder-shown) + label,
    input:focus + label {
      transform: translate(0.5rem, 4.8rem) scale(1);
    }
  }

  .dropdown-opened {
    display: flex;
    flex-direction: column;
    background-color: $whiteColor;
    z-index: 3;
    overflow: hidden;
    border-radius: 0 0 0.4rem 0.4rem;
    border: 0.1rem solid $paleBlueColor;
    border-top: 0 none;
    height: auto;
    position: absolute;
    top: 3.9rem;
    left: 0;
    width: 100%;
    list-style: none;
    margin: 0;
    max-height: 40vh;
    overflow-y: auto;
    padding: 0 1rem;

    &.upSideDown {
      top: auto;
      bottom: 3.9rem;
      border-bottom: 0 none;
      border-top: 0.1rem solid $paleBlueColor;
      border-radius: 0.4rem 0.4rem 0 0;
    }

    li {
      height: 3.9rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      border-bottom: 0.1rem solid $lightGreyColor;

      &:last-child {
        border-bottom: none;
      }
      label {
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        height: 100%;
        padding: 1.1rem 0;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }
}
