.preview {
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primaryColor;
  overflow: auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .close-preview {
    cursor: pointer;
    position: absolute;
    top: 4rem;
    right: 4rem;
    &:hover {
      opacity: 0.7;
    }
  }
  .preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 63.5%;
    padding: 4rem 0rem;

    .preview-device {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      background: #1e335e;
      border-radius: 2.9rem;
      filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
      p {
        padding: 1.25rem 2rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: $paleBlueColor;
        cursor: pointer;
        transition: 0.3s all ease;
        &.selectedDevice {
          background: $whiteColor;
          border-radius: 4.4rem;
          color: $primaryColor;
        }

        &:hover {
          background: $paleBlueColor;
          border-radius: 4.4rem;
          color: $primaryColor;
        }
      }
      div {
        cursor: pointer;
        width: 4.3rem;
        height: 4.3rem;
        transition: 0.3s all ease;
        display: flex;
        align-items: center;
        justify-content: center;
        &.selectedDevice {
          background-color: $whiteColor;
          border-radius: 4.4rem;
          color: $primaryColor;
        }
        .preview-image-box {
          display: block;
          transition: none;
          &.portrait {
            width: 1.8rem;
            height: 1.3rem;
            background: url('@/assets/icons/portraitIcons.svg') -0 -0rem;
            &.activePreviewRotation {
              background: url('@/assets/icons/portraitIcons.svg') -0 -1.3rem;
            }
          }
          &.landscape {
            width: 1.4rem;
            height: 1.7rem;
            background: url('@/assets/icons/landscapeIcons.svg') -0 -0rem;
            &.activePreviewRotation {
              background: url('@/assets/icons/landscapeIcons.svg') -0 -1.7rem;
            }
          }
        }
        &:hover {
          background: $paleBlueColor;
          border-radius: 4.4rem;
          .preview-image-box {
            border-radius: 0;
          }
        }
      }
    }
  }

  .preview-container {
    transform: scale(0.66);
    transform-origin: top;
    .preview {
      position: relative;
      margin-left: 50%;
      //   transform: translateX(-50%) scale(0.45);
      background-size: contain;
      background-repeat: no-repeat;
      transform-origin: top center;
      padding: 4rem;

      iframe {
        width: 100%;
        height: 100%;
        border-radius: 1.6rem;
        border: 0 none;
        outline: none;
        background: #fff;
      }

      &.landscape {
        background-image: url('assets/icons/landscapeTablet.svg');
      }

      &.portrait {
        background-image: url('assets/icons/portraitTablet.svg');
      }

      &.ipadProLand {
        width: 135.6rem;
        height: 104.4rem;
      }

      &.ipadProPort {
        width: 104.4rem;
        height: 135.6rem;
      }

      &.ipadAirLand {
        width: 109rem;
        height: 84rem;
      }

      &.ipadAirPort {
        width: 84rem;
        height: 109rem;
      }
      &.ipadMiniLand {
        width: 102.4rem;
        height: 78.8rem;
      }
      &.ipadMiniPort {
        width: 78.8rem;
        height: 102.4rem;
      }
    }
  }
  .preview-table {
    width: 100vw;
    margin-top: -22.5vh;
    .table-tests {
      transform-origin: top center;
      margin: 5rem auto 2rem;
      background: $whiteColor;
      width: 100%;
      border-collapse: collapse;
      caption,
      .caption {
        background: #e3e5e9;
        color: $primaryColor;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-transform: uppercase;
        padding: 2rem;
      }
      tr.error {
        td {
          color: #ff6565;
          padding-top: 0;
        }
      }
      td,
      th {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        padding: 2rem 1.65rem;
        text-align: left;
        color: $blackColor;
      }
      th {
        background: $lightGreyColor;
        color: $primaryColor;
      }
    }
  }

  @include respond(tablet) {
    .preview-header {
      width: calc(100% - 4rem);
      padding-top: 12rem;
    }

    .close-preview {
      right: 2rem;
      top: 4rem;
    }
    .preview-table {
      .table-tests {
        overflow: auto;
      }
    }
  }
}
