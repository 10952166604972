@font-face {
  font-family: 'Prompt';
  src: url('@/fonts/Prompt-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Prompt';
  src: url('@/fonts/Prompt-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Prompt';
  src: url('@/fonts/Prompt-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Prompt';
  src: url('@/fonts/Prompt-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Prompt';
  src: url('@/fonts/Prompt-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Prompt';
  src: url('@/fonts/Prompt-Thin.ttf');
  font-weight: 200;
}
* {
  font-family: 'Prompt';
  margin: 0;
  padding: 0;
  text-decoration: none;
}
