.navigation {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  background: $primaryColor;
  padding: 0 2rem;
  .logo {
    width: 18rem;
    display: flex;
    align-items: center;

    img {
      height: 26px;
    }
  }

  .navigation-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    &.main-navigation {
      width: calc(100% - 42rem);
      .nav-item {
        width: calc(100% / 4);
      }
    }
    &.sign-out-navigation {
      justify-content: flex-end;
      width: 18rem;
      gap: 50px;
      .userName {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $paleBlueColor;
      }
      .linkIcons {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          cursor: pointer;
          height: 100%;
          padding: 1rem;
          width: 3.8rem;
          height: 3.8rem;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      &.activePage {
        background: $whiteColor;

        p {
          color: $primaryColor;
        }

        &:hover {
          background: $whiteColor;
        }
      }

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        padding-left: 1rem;
        color: $whiteColor;
      }
      &:hover {
        cursor: pointer;
        background: $paleBlueColor;
      }
      .navigation-image-box {
        display: block;
        width: 1.5rem;
        height: 1.8rem;
        &.forms {
          background: url('@/assets/icons/formsIcons.svg') -0 -0rem;
          &.activeRoute {
            background: url('@/assets/icons/formsIcons.svg') -0 -1.8rem;
          }
        }
        &.statistics {
          background: url('@/assets/icons/statisticsIcons.svg') -0 -0rem;
          &.activeRoute {
            background: url('@/assets/icons/statisticsIcons.svg') -0 -1.8rem;
          }
        }
        &.devices {
          background: url('@/assets/icons/deviceIcons.svg') -0 -0rem;
          &.activeRoute {
            background: url('@/assets/icons/deviceIcons.svg') -0 -1.8rem;
          }
        }
        &.instructions {
          width: 1.7rem;
          background: url('@/assets/icons/informationIcons.svg') -0 -0rem;
          &.activeRoute {
            background: url('@/assets/icons/informationIcons.svg') -0 -1.8rem;
          }
        }
      }
    }
  }

  .navigation-hamburger,
  .navigation-options {
    display: none;
  }

  @include respond(tablet) {
    padding: 0 2rem;
    .navigation-desktop {
      display: none;
    }
    .navigation-hamburger {
      display: flex;
      align-items: center;
      button {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
      }
    }
    .navigation-options {
      position: fixed;
      top: 5rem;
      left: 0;
      width: 100%;
      height: calc(100% - 5rem);
      background: $primaryColor;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 5;

      .nav-item-wrapper {
        height: calc(100% / 7);
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $whiteColor;
        width: 100%;
        &:last-child {
          .nav-item {
            justify-content: flex-end;
          }
        }
        .nav-item {
          display: flex;
          align-items: center;
          width: 30rem;
          height: 100%;
          &.user-name {
            p {
              padding: 0;
              margin: 0;
              color: $paleBlueColor;
              text-align: center;
            }
          }
          p {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: $whiteColor;
            margin-left: 1rem;
            padding: 2rem 0;
          }

          .navigation-image-box {
            display: block;
            width: 1.5rem;
            height: 1.8rem;
            &.forms {
              background: url('@/assets/icons/formsIcons.svg') -0 -0rem;
            }
            &.statistics {
              background: url('@/assets/icons/statisticsIcons.svg') -0 -0rem;
            }
            &.devices {
              background: url('@/assets/icons/deviceIcons.svg') -0 -0rem;
            }
            &.instructions {
              width: 1.7rem;
              background: url('@/assets/icons/informationIcons.svg') -0 -0rem;
            }
          }
        }

        .dropdown {
          margin: 0;
          max-width: 30rem;
          .dropdown-outside {
            .brand-text {
              color: $whiteColor;

              background-color: $primaryColor;
            }

            p {
              &.normalColor {
                color: $whiteColor;
              }
            }
            &:hover {
              border: 0.1rem solid $whiteColor;
              .imageContainer {
                border-left: 0.1rem solid $whiteColor;
              }
            }
          }
          .dropdown-opened {
            background-color: $primaryColor;
            border: 0.1rem solid $whiteColor;
            .dropdown-title {
              border-bottom: 0.1rem solid $whiteColor;
              p {
                color: $whiteColor;
              }
              .imageContainer {
                border-left: 0.1rem solid $whiteColor;
              }
            }
            > ul {
              li {
                label {
                  color: $whiteColor;
                }
              }
            }
          }
        }
      }
    }
  }
  .desktop-navigation-additional {
    display: flex;
    flex-direction: column;
    width: 30rem;
    position: absolute;
    top: 5rem;
    right: 0;
    background-color: $primaryColor;
    z-index: 6;

    .desktop-navigation-additional-box {
      display: flex;
      flex-direction: column;
      height: fit-content;
      .desktop-navigation-additional-box-option {
        padding: 0 2rem;
        min-height: 6rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        border-top: 1px solid $whiteColor;

        &:hover {
          .desktop-navigation-additional-box-option-text {
            opacity: 0.7;
          }

          img {
            opacity: 0.7;
          }
        }

        &.dropdown-box-option {
          min-height: 9rem;
          cursor: default;
        }

        &:last-child {
          justify-content: flex-end;
        }
        .desktop-navigation-additional-box-option-text {
          font-size: 12px;
          font-weight: 600;
          line-height: 18.14px;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          color: $whiteColor;
        }

        .dropdown {
          margin: 0;
          .dropdown-outside {
            .brand-text {
              color: $whiteColor;

              background-color: $primaryColor;
            }

            p {
              &.normalColor {
                color: $whiteColor;
              }
            }
            &:hover {
              border: 0.1rem solid $whiteColor;
              .imageContainer {
                border-left: 0.1rem solid $whiteColor;
              }
            }
          }
          .dropdown-opened {
            background-color: $primaryColor;
            border: 0.1rem solid $whiteColor;
            .dropdown-title {
              border-bottom: 0.1rem solid $whiteColor;
              p {
                color: $whiteColor;
              }
              .imageContainer {
                border-left: 0.1rem solid $whiteColor;
              }
            }
            > ul {
              li {
                label {
                  color: $whiteColor;
                }
              }
            }
          }
        }
      }
    }
  }
}
