.checkbox-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }

  input[type='checkbox'] + label {
    background-image: url('~@/assets/icons/checkbox.svg');
  }

  input[type='checkbox']:checked + label {
    background-image: url('~@/assets/icons/checkboxChecked.svg');
  }

  input[type='checkbox'] + label {
    padding-left: 3.4rem;
    background-size: 1.9rem;
    min-height:2rem;
    background-position: left top;
    background-repeat: no-repeat;
    margin: 0;
    cursor: pointer;
    position: relative;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: $blackColor;
    padding-top: 0.2rem;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  &.is-invalid {
    label {
      color: $redColor;
    }
  }
}
