.search-dropdown {
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 64rem;
  margin-top: 2rem;
  @include respond(tabletBegin) {
    max-width: 58rem;
    width: 100%;
    padding: 0 2rem;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }

  input[type='radio'] + label,
  input[type='checkbox'] + label {
    padding-left: 3.5rem;
    background-size: 1.9rem;
    // height: 19px;
    background-position: left center;
    background-repeat: no-repeat;
    margin: 0;
    cursor: pointer;
    position: relative;
  }
  input[type='radio'] + label,
  input[type='checkbox'] + label {
    background-image: url('~@/assets/icons/checkbox.svg');
  }
  input[type='radio']:checked + label,
  input[type='checkbox']:checked + label {
    background-image: url('~@/assets/icons/checkboxChecked.svg');
  }
  .search-opened {
    display: flex;
    flex-direction: column;
    background-color: $whiteColor;
    z-index: 3;
    overflow: hidden;
    border-radius: 0 0 0.4rem 0.4rem;
    border: 0.1rem solid $paleBlueColor;
    border-top: 0 none;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    top: 3.9rem;
    left: 0;
    width: 100%;
    @include respond(tabletBegin) {
      width: calc(100% - 4rem);
      left: 2rem;
    }
    &.upSideDown {
      top: auto;
      bottom: 3.9rem;
      border-bottom: 0 none;
      border-top: 0.1rem solid $paleBlueColor;
      border-radius: 0.4rem 0.4rem 0 0;
    }

    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 40vh;
      overflow-y: auto;
      padding: 0 1rem;
      li {
        height: 3.9rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-bottom: 0.1rem solid $lightGreyColor;

        &:last-child {
          border-bottom: none;
        }
        label {
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          line-height: 100%;
          color: $blackColor;
          width: 25rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 1.25rem 0px;
          padding-left: 3.5rem;
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .search-outside {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    height: 3.9rem;
    width: 100%;
    background: $whiteColor;
    box-sizing: border-box;
    &.openedFilter {
      input {
        border-radius: 0.4rem 0.4rem 0 0;
      }
      &.upSideDownFilter {
        input {
          border-radius: 0 0 0.4rem 0.4rem;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
          transform: translate(0.5rem, 4.8rem) scale(1);
        }
      }
    }
    .input-row {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}
