$primaryColor: #1a2c51;
$paleBlueColor: #8d96a8;

$redColor: #ff6565;
$greenColor: #7cd920;
$yellowColor: #ffe665;
$lightGreyColor: #e7e7e7;
$orangeColor: #ffaa32;
$veryLightGreyColor: #f5f5f5;

$whiteColor: #fff;
$blackColor: #000;

$greyBorderColor: #d1d1d1;
$tableColor: #ebebeb;
$tableHover: #d3d7dc;
$statTitleColor: #e8eaed;
