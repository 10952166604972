@mixin respond($breakpoint) {
  @if $breakpoint == tablet {
    @media (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == phone {
    @media (max-width: 700px) {
      @content;
    }
  }
}
