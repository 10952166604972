.input-row {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 3.9rem;
  position: relative;

  &.isInvalid {
    input {
      border: 0.1rem solid $redColor;

      &:focus {
        border: 0.1rem solid $redColor;
      }
    }
    label {
      color: $redColor;
    }
    input:focus + label {
      color: $redColor;
    }
    input:not(:placeholder-shown) + label {
      color: $redColor;
    }
  }

  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: $blackColor;
    outline: none;
    border: 1px solid $paleBlueColor;
    border-radius: 0.4rem;
    padding: 1rem;
    width: 100%;
    height: 3.9rem;
    background: $whiteColor;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &:hover,
    &:focus {
      cursor: pointer;
      border: 0.1rem solid $primaryColor;
    }
  }

  label {
    display: inline-block;
    flex-shrink: 1;
    flex-grow: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: $paleBlueColor;
    background-color: $whiteColor;
    padding: 5px;
    position: absolute;
    top: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  input:placeholder-shown + label {
    transform: translate(10px, 4px) scale(1);
  }
  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }
  input:not(:placeholder-shown) + label,
  input:focus + label {
    color: $blackColor;
    font-size: 12px;
    transform: translate(10px, -15px) scale(1);
  }
  label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  &.input-row-read-only {
    input {
      border-color: $lightGreyColor;
      &:hover,
      &:focus {
        cursor: default;
        border-color: $lightGreyColor;
      }
    }
    label {
      &:hover {
        cursor: default;
      }
    }
  }
}
