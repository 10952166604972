.form-navigation {
  position: sticky;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: $primaryColor;
  padding: 0rem 4rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-button-container {
    display: flex;
    flex-grow: 1;
    width: 25%;
    .back-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: $whiteColor;
        padding-left: 1.5rem;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  h1 {
    flex-grow: 1;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: $whiteColor;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
  }
  .buttons-header {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2rem 4rem;

      &:last-child {
        padding-right: 0;
      }
      p {
        margin-left: 1rem;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: $whiteColor;
      }

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}

.new-form {
  background-color: $lightGreyColor;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 4rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .warning-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: $redColor;
    margin-bottom: 4rem;
    text-align: center;
    text-transform: uppercase;
  }
  .default-text {
    font-size: 14px;
    line-height: 100%;
    font-weight: 600;
    color: $primaryColor;
    text-align: center;
    text-transform: uppercase;
    padding: 2rem;
  }

  .form-fieldset {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: $whiteColor;
    border-radius: 0.8rem;
    margin-bottom: 0.2rem;
    max-width: 100vw;
    margin-top: 1rem;
    border: 0 none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }
    &:nth-child(1) {
      border-radius: 0.8rem 0.8rem 0 0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      border-radius: 0;
    }
    &:nth-child(4) {
      border-radius: 0 0 0.8rem 0.8rem;
    }
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: $primaryColor;
      text-transform: uppercase;
      padding-bottom: 20px;
      &.isInvalid {
        color: $redColor;
      }
    }

    .sub-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: $blackColor;
      margin: 2rem 0;
    }

    &.models {
      padding: 0;
      position: relative;
      .title {
        margin-top: 2rem;
        margin-left: 2rem;
      }
      .sub-title {
        margin-bottom: 1rem;
        margin-left: 2rem;
      }
      .additional-container {
        border-top: 0;
        padding: 2rem;
      }
      .model-config-icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .data-container {
      display: flex;
      width: 100%;
      margin-top: 2rem;
      .data-preview {
        width: calc(100% / 3);
        margin-right: 2rem;
        border-right: 0.1rem solid $lightGreyColor;
        min-height: 100%;
        height: auto;
        &:last-child {
          border-right: none;
          margin-right: 0;
        }
        .data-preview-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: $primaryColor;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }
        .data-preview-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          margin-top: 0.5rem;
          color: $blackColor;
        }
      }
    }
    .input-column-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 108rem;
      margin: 0 auto;
      gap: 2rem;

      .input-column-group-rules {
        display: flex;
        gap: 20px;

        .input-column-group-rules-button {
          padding-top: 9px;
          .button {
            &.delete-button {
              background: $whiteColor;
              border: 0.1rem solid $redColor;
              color: $redColor;
            }
          }
        }
      }

      .input-column-group-rules-add {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .button {
          &.primary-button {
            background: $primaryColor;
            border: 0.1rem solid $primaryColor;
            color: $whiteColor;
          }
        }
      }
      .input-column-group-question {
        display: flex;
        padding-top: 20px;
        border-top: 1px solid $lightGreyColor;
      }
    }
    .input-container {
      display: flex;
      gap: 4rem;
      max-width: 108rem;
      margin: 0 auto;

      &.single-input-container {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 54rem;
        margin: 0 auto;
        .checkbox-component {
          width: 100%;
        }
        .input-group {
          width: 100%;
        }
        .financial-services {
          width: 100%;
          max-width: 54rem;
          margin-top: 2rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .financial-services-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: $paleBlueColor;
            margin-right: 4rem;
          }
        }
      }

      .input-group {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &.line-up-input-group {
          padding-top: 5.9rem;
        }

        .input-splitter {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          gap: 4rem;
          .input-row {
            width: calc(50% - 2rem);
          }
          .checkbox {
            width: calc(50% - 2rem);
            label {
              line-height: 130%;
            }
            input[type='checkbox'] + label::before {
              margin-top: 0;
            }
          }
        }

        .checkbox {
          display: flex;
          align-items: center;
          width: auto;
          input[type='checkbox'] {
            position: absolute;
            opacity: 0;
            left: -99999px;
          }

          input[type='checkbox'] + label::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('~@/assets/icons/checkboxIconsUpdated.svg') 0 0;
            background-repeat: no-repeat;
            margin-right: 15px;
            margin-top: -3px;
            flex-shrink: 0;
          }

          input[type='checkbox']:checked + label::before {
            background-position: 0 -21px;
          }

          label {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: $blackColor;
            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }

        .text-color {
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 4rem;
          gap: 4rem;
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: $paleBlueColor;
          }
          .radio-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: auto;
            gap: 4rem;
            .color {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              input[type='radio'] {
                position: absolute;
                opacity: 0;
                left: -99999px;
              }

              input[type='radio'] + label {
                background-image: url('~@/assets/icons/radio.svg');
              }

              input[type='radio']:checked + label {
                background-image: url('~@/assets/icons/radioChecked.svg');
              }
              input[type='radio'] + label {
                padding-left: 3.4rem;
                background-size: 1.9rem;
                height: 1.9rem;
                background-position: left center;
                background-repeat: no-repeat;
                margin: 0;
                cursor: pointer;
                position: relative;
              }

              label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                padding-top: 0.2rem;
                color: $blackColor;
                &:hover {
                  cursor: pointer;
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }

    &.gap {
      padding: 0;
      .title {
        padding: 2rem;
      }

      .sub-title {
        padding: 0 2rem;
      }
    }

    .filter {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      .filter-dropdown-component {
        margin-top: 1rem;
      }
    }
    .selected-container {
      border-top: 0.1rem solid $lightGreyColor;
      display: flex;
      padding: 0 2rem;
      padding-top: 2rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .title {
        margin: 0;
      }
      .sub-title {
        margin: 2rem 0;
      }
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem;
        height: 6rem;
        border: 0.1rem solid $lightGreyColor;
        border-radius: 0.4rem;
        margin-bottom: 1rem;
        column-gap: 2rem;

        &:last-child {
          margin-bottom: 2rem;
        }
        &.oneItem {
          border-bottom: 0 none;
        }
        .information {
          width: calc(100% - 24rem);
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          column-gap: 2rem;
          .edit-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
            column-gap: 2rem;
            img {
              width: 6rem;
              height: 6rem;
              object-fit: contain;
              &.interest-image {
                height: 3.9rem;
                width: 6.9rem;
              }
            }
            .name {
              margin-left: 2rem;
              font-weight: 400;
              font-size: 14px;
              line-height: 130%;
              color: $blackColor;
            }
            .input-group {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              column-gap: 4rem;
              padding: 0;
              flex: 1;
              width: 100%;
              .input-row {
                margin: 0;
                height: 4rem;
                width: 50%;
              }
            }
          }
          .codes {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 2rem;
            min-width: 8rem;
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 130%;
              color: $paleBlueColor;
            }
          }
        }
        .buttons-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 240px;

          button {
            margin-right: 1rem;

            &:last-child {
              margin-right: 0;
            }
            &.delete-button {
              background: $whiteColor;
              border: 0.1rem solid $redColor;
              color: $redColor;
              p {
                color: $redColor;
              }
            }
            &.blue-button {
              background: $primaryColor;
              border: 0.1rem solid $primaryColor;
              color: $whiteColor;
              p {
                color: $whiteColor;
              }
            }
          }
        }
      }
    }
    .retailers-filters {
      display: flex;
      gap: 40px;
      width: 100%;
      max-width: 540px;
      margin: 0 auto;
      padding-bottom: 20px;
      p {
        font-weight: 400;
        font-size: 14px;
        color: $blackColor;
        line-height: 100%;
        padding-top: 4px;
      }

      .checkbox {
        display: flex;
        align-items: center;
        width: auto;
        input[type='checkbox'] {
          position: absolute;
          opacity: 0;
          left: -99999px;
        }

        input[type='checkbox'] + label::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('~@/assets/icons/checkboxIconsUpdated.svg') 0 0;
          background-repeat: no-repeat;
          margin-right: 15px;
          margin-top: -3px;
          flex-shrink: 0;
        }

        input[type='checkbox']:checked + label::before {
          background-position: 0 -21px;
        }

        label {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: $blackColor;
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
    .additional-container {
      border-top: 0.2rem solid $lightGreyColor;
      padding: 3rem 0px 0px 2rem;
      &.selected {
        padding: 2rem;
      }
      .title {
        text-transform: uppercase;
        padding: 0;
      }
      .sub-title {
        padding: 0;
      }

      .select-all {
        display: inline-block;
        padding: 0.5rem 1.2rem 0.4rem 1.2rem;
        font-weight: 600;
        font-size: 10px;
        width: auto;
        line-height: 100%;
        color: $whiteColor;
        background-color: $primaryColor;
        border-radius: 4.4rem;
        text-transform: uppercase;
        margin-bottom: 3rem;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
      .model-tree-new {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        flex-basis: 100%;
        row-gap: 1rem;
        overflow: hidden;
        .model-tree-headers {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          min-width: 85rem;
          column-gap: 0.5rem;
          &.no-variants {
            .model-tree-header {
              width: calc(100% / 2 - 0.5rem);
            }
          }

          .model-tree-header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 44px;
            width: calc(100% / 3 - 0.33rem);
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: $primaryColor;
            padding: 0 1.5rem;
            background-color: $lightGreyColor;
            border-radius: 0.5rem;
            &.checkbox-active {
              padding: 0 1rem;
            }
            .checkbox-component {
              label {
                font-weight: 600;
                font-size: 14px;
                line-height: 100%;
                color: $primaryColor;
              }
            }
          }
        }
        .model-tree-new-data {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          min-width: 85rem;

          column-gap: 5px;
          &.no-variants {
            .model-tree-list {
              width: calc(100% / 2 - 0.5rem);
            }
          }
          .model-tree-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            row-gap: 0.2rem;
            width: calc(100% / 3 - 0.33rem);

            .model-tree-list-item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
              height: 4.4rem;
              padding: 0.5rem;
              background-color: $veryLightGreyColor;
              border: 1px solid $lightGreyColor;
              border-radius: 0.5rem;
              column-gap: 5px;
              &.series {
                padding: 1.5rem;
                &:not(.hasChildren) {
                  display: none;
                }
              }

              &.active {
                background-color: $whiteColor;
                p {
                  &::after {
                    display: none;
                  }
                }
                .expand {
                  display: none;
                }
              }

              input[type='checkbox'] {
                position: absolute;
                opacity: 0;
                z-index: -1;
              }

              input[type='checkbox'] + label,
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                color: $blackColor;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                span {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 100%;
                  color: $blackColor;
                }
                img {
                  width: 5rem;
                  height: 2.8rem;
                  margin-right: 1rem;
                  object-fit: contain;
                }
                &:hover {
                  cursor: pointer;
                  opacity: 0.7;
                }
              }
              input[type='checkbox'] + label::before {
                content: '';
                display: inline-block;
                width: 1.9rem;
                height: 1.9rem;
                margin-right: 1rem;
                padding: 0.5rem;

                background-image: url('~@/assets/icons/checkbox.svg');
                background-repeat: no-repeat;
                background-position: center;
              }
              input[type='checkbox']:checked + label::before {
                background-image: url('~@/assets/icons/checkboxChecked.svg');
              }

              .expand {
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                background-image: url('~@/assets/icons/expandArrow.svg');
                background-repeat: no-repeat;
                background-size: contain;
                margin-left: auto;
                &:hover {
                  cursor: pointer;
                  background-image: url('~@/assets/icons/expandArrowHover.svg');
                }
              }
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                color: $blackColor;
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;

                &::after {
                  content: '';
                  display: inline-block;
                  width: 1.4rem;
                  height: 1.4rem;
                  background-image: url('~@/assets/icons/expandArrow.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-left: auto;
                }
              }
              &:hover {
                border: 1px solid $primaryColor;
                p {
                  color: $primaryColor;
                  &:after {
                    background-image: url('~@/assets/icons/expandArrowHover.svg');
                  }
                }
                &.series {
                  cursor: pointer;
                }
              }

              &.disabled {
                border: 1px solid $lightGreyColor;

                img {
                  opacity: 0.5;
                }

                p {
                  color: $paleBlueColor;
                  cursor: not-allowed;

                  &::after {
                    display: none;
                  }

                  &:hover {
                    cursor: not-allowed;
                    opacity: 1;
                  }
                }
                cursor: not-allowed;
                .expand {
                  display: none;
                }
              }
            }
          }
        }
      }

      .checkbox-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 2rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
        .checkbox {
          display: flex;
          align-items: center;
          width: calc(25% - 1.5rem);

          img {
            margin-right: 10px;
            margin-top: -2px;
            flex-shrink: 0;
          }

          input[type='checkbox'] {
            position: absolute;
            opacity: 0;
            left: -99999px;
          }

          input[type='checkbox'] + label::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('~@/assets/icons/checkboxIconsUpdated.svg') 0 0;
            background-repeat: no-repeat;
            margin-right: 15px;
            margin-top: -3px;
            flex-shrink: 0;
          }

          input[type='checkbox']:checked + label::before {
            background-position: 0 -21px;
          }

          label {
            display: flex;
            align-items: center;
            font-weight: 400;
            width: 100%;
            height: 100%;
            font-size: 14px;
            line-height: 100%;
            color: $blackColor;
            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

@include respond(tablet) {
  .form-navigation {
    padding: 0 2rem;
    .back-button {
      flex-grow: 0;
    }
    h1 {
      width: 100%;
      font-size: 18px;
    }
    .buttons-header {
      .button-container {
        display: none;
      }
    }
  }
  .new-form {
    padding: 2rem;
    padding-bottom: 80px;
    .form-fieldset {
      .data-container {
        flex-direction: column;
        .data-preview {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-right: 0rem;
          margin-bottom: 2rem;
          border-right: none;
          border-bottom: 0.1rem solid $lightGreyColor;
          &:last-child {
            border-right: none;
            margin-right: 0;
            border-bottom: none;
          }

          .data-preview-desc {
            margin-top: 0;
            margin-bottom: 0.5rem;
          }
        }
      }

      .input-column-group {
        width: 100%;
        max-width: 54rem;
      }
      .input-container {
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 0;
        gap: 2rem;

        .input-group {
          margin: 0;
          width: 100%;
          max-width: 54rem;
          &.line-up-input-group {
            padding-top: 0;
          }
          .input-splitter {
            flex-direction: column;
            width: 100%;
            align-items: center;
            gap: 2rem;
            .input-row {
              width: 100%;
            }
            .checkbox {
              width: 100%;
            }
          }

          .text-color {
            min-height: auto;
          }
        }
      }

      .additional-container {
        max-width: calc(100vw - 4rem);
        .model-tree-new {
          overflow-x: auto;
        }
        .model-tree {
          overflow-x: auto;
          .model-tree-series {
            min-width: 24rem;
          }
          .model-tree-constructionSeries,
          .model-tree-agModel,
          .model-tree-ukModel {
            min-width: 24rem;
          }
        }
        .checkbox-container {
          .checkbox {
            width: calc(100% / 2 - 1rem);
          }
        }
      }
      .selected-container {
        .item {
          flex-direction: column;
          align-items: flex-start;
          height: auto;
          row-gap: 2rem;
          .information {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            row-gap: 1rem;
            .edit-container {
              flex-direction: column;
              align-items: flex-start;
              row-gap: 1rem;
              width: 100%;
              .name {
                margin: 0;
              }

              .input-group {
                max-width: 100%;
                width: 100%;
                .input-row {
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }
          .buttons-container {
            width: 100%;
          }
        }
      }
    }
  }
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 44, 81, 0.2);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border: 0.5rem solid $whiteColor;
    border-top: 0.5rem solid $primaryColor;
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.error-breakdown {
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 5.4rem 2rem 2rem;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.8rem 0.8rem 0 0;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  max-width: 70rem;
  width: 100%;
  z-index: 10;
  button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  h2 {
    color: #ff6565;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 1rem;
  }
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    li {
      font-weight: 400;
      font-size: 1.4rem;
      color: #000;
      padding: 0 1rem 0 0;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &:not(:last-child) {
        border-right: 1px solid #8d96a880;
      }
    }
  }
}
@include respond(phone) {
  .new-form {
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 8rem;
    .form-fieldset {
      border-radius: 0;
      .retailers-filters {
        gap: 20px;
        padding: 0 20px 20px;
        max-width: 580px;
      }
      .additional-container {
        max-width: 100vw;

        .checkbox-container {
          .checkbox {
            width: 100%;
          }
        }
      }
      .selected-container {
        .item {
          .information {
            .edit-container {
              .input-group {
                row-gap: 2rem;
                flex-direction: column;
                .input-row {
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
