.input-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .input-group {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.second-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  padding: 2rem 4rem;
  background: rgba(141, 150, 168, 0.25);
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    color: $primaryColor;
  }
  .second-navigation-box {
    display: flex;
    align-items: center;
    width: calc(100% / 3);
    height: 100%;
    gap: 20px;
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
  .input-navigation {
    width: 100%;
    max-width: 360px;
    margin-bottom: 0;
    height: 40px;
    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: $blackColor;
      outline: none;
      border: 1px solid $paleBlueColor;
      border-radius: 0.4rem;
      padding: 1rem;
      width: 100%;
      height: 3.9rem;
      background: $whiteColor;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &:hover,
      &:focus {
        cursor: pointer;
        border: 0.1rem solid $primaryColor;
      }
    }
  }
  .button {
    max-width: 240px;
  }
}
.radios-navigation {
  display: flex;
  width: 100%;
  background-color: $veryLightGreyColor;
  padding: 0px 40px;
  height: 48px;
  .radio-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    input[type='radio'] {
      position: absolute;
      opacity: 0;
      left: -99999px;
    }
    input[type='radio'] + label,
    .radio-item {
      padding: 10px;
      width: 100%;
      text-transform: uppercase;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primaryColor;
      font-size: 14px;
      font-weight: 600;
      line-height: 130%; /* 18.2px */
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      border-bottom: 1px solid $paleBlueColor;

      &:hover {
        opacity: 0.7;
      }
    }

    input[type='radio']:checked + label,
    .radio-item-active {
      &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $primaryColor;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

footer {
  display: none;
}

.floating-footer-box {
  display: none;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 44, 81, 0.2);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-body {
    width: auto;
    height: auto;
    background: $whiteColor;
    border-radius: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ::-webkit-scrollbar-track {
      border: 3.5px solid transparent;
      // box-shadow: inset 0 0 10px 10px #b2bec3;
    }

    ::-webkit-scrollbar {
      background-color: transparent;
      border: 4px solid transparent;
      width: 8px;
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $primaryColor;
      border-radius: 10px;
      border: solid 3px transparent;
    }

    &.add-device {
      width: 95rem;

      &.add-device-ne {
        width: 58rem;
      }

      .new-device-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;
        color: $primaryColor;
        margin-bottom: 2rem;
      }

      .new-device-container-footer {
        padding-top: 40px;
        padding-bottom: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        color: $primaryColor;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
      }

      .new-device-container-ne {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 0.1rem solid $paleBlueColor;
        border-bottom: 0.1rem solid $paleBlueColor;
        padding: 4rem 0;
        gap: 20px;

        .device-box {
          display: flex;
          gap: 20px;
          padding-bottom: 2rem;
          border-bottom: 0.1rem solid $lightGreyColor;

          &:nth-child(2) {
            align-items: center;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .step {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: $whiteColor;
            background-color: $paleBlueColor;
            width: 3.2rem;
            height: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
          }
          p,
          .link {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: $blackColor;
          }
          .device-box-container {
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }
        }
      }

      .new-device-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        border-top: 0.1rem solid $paleBlueColor;
        padding-top: 4rem;
        padding-bottom: 2rem;
        .device-box {
          width: calc(100% / 3);
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          margin-right: 2rem;
          border-right: 0.1rem solid $lightGreyColor;
          height: 22.4rem;
          &:last-child {
            border-right: 0;
            margin-right: 0;
          }
          .step {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: $whiteColor;
            background-color: $paleBlueColor;
            padding: 0.6rem 1rem;
            margin-bottom: 2rem;
          }
          .device-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: $blackColor;
            margin-bottom: 2rem;
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 130%;
              color: $blackColor;
              &:hover {
                opacity: 0.7;
                color: #1a2c51;
              }
            }
          }
          .device-value {
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            color: $primaryColor;
          }
          .device-images {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 1rem;
            &:last-child {
              margin-bottom: 0;
            }
            img {
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }

    &.qr-code {
      width: calc(100% - 40px);
      max-width: 79.6rem;
      height: fit-content;
      max-height: calc(100% - 120px);
      overflow-y: auto;
      overflow-x: hidden;
      .qr-code-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid $paleBlueColor;

        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: $primaryColor;
          text-transform: uppercase;
        }
      }

      .qr-code-box {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        width: 100%;
        padding: 40px 0px;
      }

      .qr-code-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0;
        border-top: 1px solid $paleBlueColor;

        .qr-code-info-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: $primaryColor;
          text-transform: uppercase;
        }

        .link {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          text-decoration: underline;
        }
      }
      .qr-code-info-disclaimer {
        display: flex;
        padding-top: 20px;
        border-top: 1px solid $paleBlueColor;
        width: 100%;
        flex-direction: column;
        gap: 20px;

        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: $primaryColor;
          text-transform: uppercase;
        }

        .qr-code-info-disclaimer-text {
          font-weight: 400;
          line-height: 130%;
          color: $blackColor;
          text-transform: none;
        }
      }
      .qr-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
        margin-bottom: 2rem;
        margin-top: 4rem;
      }
      canvas {
        width: 22rem;
        height: 22rem;
      }
    }
    &.filter-date {
      width: 39.4rem;
      gap: 1rem;
      .filter-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $primaryColor;
        text-transform: uppercase;
      }
      .button-container {
        margin-top: 2rem;
      }
    }

    &.pda-box {
      padding: 40px;
      gap: 40px;
      width: calc(100% - 40px);
      max-width: 560px;
      .pda-text {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
        letter-spacing: 0.42px;
      }

      .button-container {
        display: flex;
        gap: 80px;
        .grey {
          background-color: $paleBlueColor;
        }
      }
    }

    &.add-forms,
    &.group-statistics {
      width: 41rem;
      .add-form-subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $paleBlueColor;
      }

      .group-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
      }

      .add-form-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $primaryColor;
        margin-bottom: 1rem;
      }

      .add-form-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
        margin-bottom: 1rem;
      }

      input[type='checkbox'],
      input[type='radio'] {
        position: absolute;
        opacity: 0;
        left: -99999px;
      }

      input[type='radio'] + label:before,
      input[type='checkbox'] + label:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('~@/assets/icons/checkboxIconsUpdated.svg') 0 0;
        background-repeat: no-repeat;
        margin-right: 15px;
        margin-top: -3px;
        flex-shrink: 0;
      }
      input[type='radio']:checked + label:before,
      input[type='checkbox']:checked + label:before {
        background-position: 0 -21px;
      }
      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        max-height: 40vh;
        width: 100%;
        overflow-y: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        border-top: 0.1rem solid $paleBlueColor;
        border-bottom: 0.1rem solid $paleBlueColor;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 3.9rem;
          label {
            display: flex;

            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: $blackColor;
            padding: 1.5rem 0px;
            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
          }
        }
      }
    }

    &.auth-device {
      padding: 4rem;
      width: auto;
      max-width: 63rem;
      .auth-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
        &:first-child {
          margin-bottom: 2rem;
        }
        .auth-name {
          font-weight: 700;
          font-size: 14px;
          line-height: 130%;
          color: $primaryColor;
        }
      }
      .button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4rem;
        button {
          &:first-child {
            margin-right: 4rem;
            background-color: $paleBlueColor;
          }
        }
      }
    }

    &.duplicate {
      width: 31rem;
      height: auto;

      .duplicate-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $primaryColor;
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
      .duplicate-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
        padding-top: 2rem;
        border-top: 0.1rem solid $paleBlueColor;
        width: 100%;
      }
      .button {
        margin-top: 2rem;
      }
    }
    .close-icon {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      img {
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
.table-container {
  padding: 0 4rem;
  background: linear-gradient(to bottom, $veryLightGreyColor 52.5px, transparent 52.5px);

  .table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: none;

    .table-row {
      display: table-row;
      width: 100%;
      border-bottom: 1px solid $lightGreyColor;

      &.table-header {
        border-bottom: 0 none;

        .table-data {
          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: $primaryColor;
            padding: 1.9rem 0;
          }
        }
      }
      .table-data {
        display: table-cell;
        text-align: left;
        vertical-align: middle;
        .add-form-container {
          display: flex;
          justify-content: flex-start;
          padding: 1.6rem 0;
          align-items: flex-start;

          .table-value {
            width: 100%;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0;
            color: $primaryColor;
            margin-left: 1rem;
          }
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
        .name-container {
          display: flex;
          justify-content: flex-start;
          padding: 1.6rem 0;
          align-items: flex-start;
          width: 100%;
          &.device {
            .ellipsis {
              margin-left: 0;
            }
          }
          img {
            margin-top: 0.1rem;
            &:hover {
              opacity: 0.7;
              cursor: pointer;
            }
          }
          // .table-value {
          //   width: 250px;
          //   white-space: nowrap;
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   padding: 0;
          //   margin-left: 1rem;
          // }
          .ellipsis {
            position: relative;
            display: flex;
            flex: 1;
            margin-left: 1rem;
            min-width: 200px;
            max-width: calc(100% - 4rem);
            .table-value {
              padding: 0;
              position: absolute;
              left: 0;
              right: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:before {
              content: ' ';
              visibility: hidden;
            }
          }
        }
        .qrIcon {
          width: 2.1rem;
          height: 2.1rem;
          margin-left: 20%;
          vertical-align: middle;
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
        .table-desc {
          display: none;
        }
        .table-value {
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          color: $blackColor;
          padding: 1.6rem 0;
          .checkbox-device {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: auto;
            margin-left: 25%;
            input[type='checkbox'] {
              position: absolute;
              opacity: 0;
              left: -99999px;
            }

            input[type='checkbox'] + label::before {
              content: '';
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url('~@/assets/icons/checkboxIconsUpdated.svg') 0 0;
              background-repeat: no-repeat;
              margin-top: -3px;
              flex-shrink: 0;
            }

            input[type='checkbox']:checked + label::before {
              background-position: 0 -21px;
            }

            label {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-weight: 400;
              font-size: 14px;
              line-height: 100%;
              color: $blackColor;
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
            }
          }
          .time {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: $paleBlueColor;
            margin-left: 1rem;
          }

          &.uppercase {
            text-transform: uppercase;
          }
          &.statusScheduled {
            color: $orangeColor;
          }
          &.statusExpired {
            color: $paleBlueColor;
          }
          &.statusActive {
            color: $greenColor;
          }
        }
      }
    }
  }
}

@include respond(tablet) {
  .modal-background {
    .modal-body {
      &.add-device {
        width: 90%;
      }
    }
  }
  .second-navigation {
    padding: 0 2rem;
    height: 40px;
    .button {
      display: none;
    }
    .title {
      font-size: 18px;
    }
    .second-navigation-box {
      width: 100%;
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
    .input-navigation {
      display: none;
    }
  }
  .radios-navigation {
    padding: 0 20px;
  }

  .floating-footer-box {
    width: calc(100% - 40px);
    max-width: 560px;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background: $whiteColor;
    border: 1px solid #d1d1d1;
    box-shadow: 0px 0px 25px rgba(26, 44, 81, 0.3);
    border-radius: 20px;
    height: 39px;

    &.no-footer-floating {
      bottom: 20px;
    }
    .floating-footer-icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 51px;
    }
    .floating-footer-text-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d1d1d1;
      border-left: 1px solid #d1d1d1;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: $primaryColor;
      text-transform: uppercase;
    }

    .close-search-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 51px;
      padding: 14px 19px;
    }
    .search-footer-input-box {
      width: 100%;
      height: 100%;
      position: relative;
      border-right: 1px solid #d1d1d1;

      input {
        width: 100%;
        height: 100%;
        border-radius: 20px 0 0 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: $blackColor;
        outline: none;
        border: none;
        padding: 1rem;
        padding-left: 20px;
        padding-right: 60px;
        background: $whiteColor;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }
      img {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  footer {
    width: calc(100% - 40px);
    max-width: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10rem;
    background-color: $primaryColor;

    // &.two-box {
    //   .footer-box {
    //     margin-right: 0.1rem;
    //     width: calc(50% - 2rem);
    //     justify-content: center;
    //     &:last-child {
    //       border-radius: 0 10rem 10rem 0;
    //       margin-right: 0;
    //     }
    //   }
    //   &.splitButtons {
    //     flex-direction: column;
    //     .footer-box {
    //       border-radius: 10rem;
    //       margin-right: 0;
    //       width: 50%;
    //       margin-bottom: 1rem;

    //       &.grouped {
    //         justify-content: space-between;
    //         width: calc(100% - 2rem);
    //         .group-footer {
    //           display: flex;
    //           justify-content: flex-start;
    //           align-items: center;
    //         }
    //         .sub-button {
    //           background-color: $whiteColor;
    //           border-radius: 4.4rem;
    //           p {
    //             padding: 0.2rem 2.75rem 0.1rem 2.75rem;
    //             margin-left: 0;
    //             font-weight: 600;
    //             font-size: 10px;
    //             line-height: 100%;
    //             color: $primaryColor;
    //           }
    //         }
    //       }

    //       &:last-child {
    //         margin-bottom: 0;
    //       }
    //     }
    //   }
    // }
    &.three-box {
      .footer-box {
        border-radius: 10rem 0 0 10rem;
        margin-right: 0.1rem;
        width: calc(33% - 2rem);
        justify-content: center;
        &:nth-child(2) {
          border-radius: 0;
        }
        &:last-child {
          border-radius: 0 10rem 10rem 0;
          margin-right: 0;
        }
      }
      &.splitButtons {
        flex-direction: column;
        .footer-box {
          border-radius: 10rem;
          margin-right: 0;
          width: 50%;
          margin-bottom: 1rem;

          &.grouped {
            justify-content: space-between;
            width: calc(100% - 2rem);
            .group-footer {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .sub-button {
              background-color: $whiteColor;
              border-radius: 4.4rem;
              p {
                padding: 0.2rem 2.75rem 0.1rem 2.75rem;
                margin-left: 0;
                font-weight: 600;
                font-size: 10px;
                line-height: 100%;
                color: $primaryColor;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .footer-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-grow: 1;
      height: 3.9rem;
      border-left: 1px solid $whiteColor;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: $whiteColor;
        text-transform: uppercase;
        margin-left: 1rem;
        padding-top: 0.2rem;
      }

      &:nth-child(1) {
        border-left: none;
      }
    }
  }
  .table-container {
    padding: 0 2rem;
    background: transparent;
    .table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .table-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &.table-header {
          display: none;
        }
        &:last-child {
          margin-bottom: 8rem;
        }
        &.device-row {
          .table-data {
            width: 16%;
          }
        }
        .table-data {
          display: flex;
          flex-direction: column;
          width: 18%;
          margin-top: 1rem;
          margin-bottom: 1rem;
          &.button-data {
            width: 5.3rem;
          }
          &.button-device {
            width: 10.1rem;
          }
          &.name {
            flex-basis: 100%;
            margin-bottom: 0rem;
            .ellipsis {
              .table-value {
                position: relative;
                width: auto;
                white-space: normal;
                text-overflow: none;
              }
            }
          }
          .add-form-container {
            padding: 0;
          }
          .qrIcon {
            margin-left: 0%;
          }
          .table-desc {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: $paleBlueColor;
            flex-shrink: 0;
          }
          .table-value {
            padding: 0;
            .checkbox-device {
              margin-left: 0;
            }
          }
          .dataType {
            width: auto;
          }
        }
      }
    }
  }
}

@include respond(phone) {
  .second-navigation {
    height: 6rem;
    padding: 0 2rem;
    .title {
      font-size: 20px;
    }
  }
  .floating-footer-box {
    bottom: 60px;
  }
  footer {
    bottom: 0;
    width: 100%;
    max-width: 100%;
    bottom: 0px;
    left: 0;
    transform: translateX(0);
    border-radius: 0;
    .footer-box {
      border-left: 1px solid $whiteColor;

      p {
        font-size: 12px;
        line-height: 100%;
        margin-left: 0rem;
        padding-top: 0.2rem;
      }
      img {
        display: none;
      }

      &:nth-child(1) {
        border-left: none;
      }
    }
  }
  .modal-background {
    .modal-body {
      &.add-device {
        height: 80%;
        overflow: auto;

        &.add-device-ne {
          height: auto;
          max-height: 80%;
          max-width: calc(100% - 4rem);
          .new-device-container-ne {
            .device-box {
              flex-direction: column;

              &:nth-child(2) {
                align-items: flex-start;
              }
            }
          }
        }
        .new-device-container {
          flex-direction: column;
          .device-box {
            height: auto;
            width: 100%;
            margin-right: 0rem;
            border-right: 0 none;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 0.1rem solid $lightGreyColor;
            &:last-child {
              border-bottom: 0;
              padding-bottom: 0;
              margin-bottom: 0;
            }
            .step {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: $whiteColor;
              background-color: $paleBlueColor;
              padding: 0.6rem 1rem 0.4rem 1rem;
              margin-bottom: 2rem;
            }
            .device-description {
              font-weight: 400;
              font-size: 14px;
              line-height: 130%;
              color: $blackColor;
              margin-bottom: 2rem;
            }
            .device-value {
              font-weight: 600;
              font-size: 14px;
              line-height: 130%;
              color: $primaryColor;
            }
            .device-images {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &.add-forms,
      &.group-statistics {
        width: 90%;
      }
      &.auth-device {
        max-width: 90%;

        .button-container {
          flex-direction: column;
          margin-top: 4rem;
          button {
            &:first-child {
              margin-bottom: 4rem;
              margin-right: 0;
            }
          }
        }
      }
      &.pda-box {
        padding: 20px;
        gap: 20px;
        width: calc(100% - 20px);

        .button-container {
          flex-direction: column;
          gap: 20px;
          align-items: center;
        }
      }
      &.filter-date {
        width: 90%;
      }
      &.duplicate {
        width: 31rem;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        .duplicate-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          color: $primaryColor;
          margin-bottom: 2rem;
          margin-top: 1rem;
        }
        .duplicate-name {
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          color: $blackColor;
          padding-top: 2rem;
          border-top: 0.1rem solid $paleBlueColor;
          width: 100%;
        }
        .button {
          margin-top: 2rem;
        }
      }
      .close-icon {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        img {
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }
  }

  .table-container {
    .table {
      .table-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;
        position: relative;
        &.table-header {
          display: none;
        }
        &.device-row {
          .table-data {
            width: 100%;
          }
        }
        .table-data {
          flex-direction: row;
          width: 100%;
          margin-top: 0.4rem;
          margin-bottom: 0.4rem;
          &:nth-last-child(2) {
            margin-bottom: 20px;
          }
          &:last-child {
            position: absolute;
            bottom: 15px;
            right: 20px;
            justify-content: flex-end;
          }

          &.button-data {
            width: fit-content;
          }

          &.table-data-centred {
            align-items: center;
          }

          &.qr-code-data {
            .table-desc {
              display: none;
            }
          }

          .table-desc {
            width: 10rem;
            line-height: 100%;
          }
          .table-value {
            padding: 0;
            line-height: 100%;
          }
          .dataType {
            width: auto;
          }
          .name-container {
            padding: 0;
            padding-top: 16px;
            padding-bottom: 6px;
            .table-value {
              font-weight: 600;
              font-size: 17px;
              line-height: 100%;
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: $blackColor;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
