.graph {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 36.7rem;
  border-right: 0.2rem solid $veryLightGreyColor;
  // &:nth-child(2n) {
  //   border-right: 0 none;
  // }

  .graph-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: $primaryColor;
    width: 100%;
    text-transform: uppercase;
    padding: 2rem;
    background-color: $statTitleColor;
  }
  ::-webkit-scrollbar-track {
    border: 0.2rem solid transparent;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    border: 0.2rem solid transparent;
    width: 0.6rem;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $paleBlueColor;
    border-radius: 0.8rem;
    border: solid 0.2rem transparent;
  }
  .graph-stats {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding: 2rem;
    .graph-stat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.1rem solid #c6cbd3;
      position: relative;
      &:last-child {
        margin-bottom: 1rem;
      }
      &.total {
        border-bottom: 0.1rem solid $primaryColor;
        margin-bottom: 3.5rem;
        padding-bottom: 0.3rem;
      }
      .block-width {
        position: absolute;
        bottom: -1px;
        height: 1px;
        background-color: $primaryColor;
      }
      .graph-stat-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $primaryColor;
        text-transform: uppercase;
      }
      .graph-stat-number {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: $primaryColor;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          color: $paleBlueColor;
        }
      }
      .graph-stat-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: $blackColor;
      }
    }
  }

  @include respond(phone) {
    height: fit-content;
  }
}
