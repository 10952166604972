.dropdown-search {
  display: inline-block;
  height: auto;
  position: relative;
  width: 100%;
  transition: all 0.2s ease-in-out;
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }
  &.isInvalid {
    .dropdown-outside {
      border: 0.1rem solid $redColor;

      p {
        color: $redColor;
      }
    }
    .dropdown-opened {
      border: 0.1rem solid $redColor;
      .dropdown-title {
        p {
          color: $redColor;
        }
      }
    }
  }

  .dropdown-outside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid $paleBlueColor;
    width: 100%;
    height: 3.9rem;
    border-radius: 0.4rem;
    position: relative;

    &.dropdown-upside-down {
      .brand-text {
        top: auto;
        bottom: -2rem;
      }
    }
    .image-box-dropdown {
      display: block;
      width: 1.9rem;
      height: 1.1rem;
      display: flex;
      align-items: center;
      margin: 0rem 1rem;
      background: url('@/assets/icons/dropdownIcons.svg') -0 -1.1rem;
    }
    p {
      border-right: 1px solid $paleBlueColor;
      padding: 1.2rem 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: $paleBlueColor;
      padding-left: 1rem;
      height: 100%;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      &.selected-value {
        color: $blackColor;
      }
    }
    .brand-text {
      position: absolute;
      top: -1rem;
      background-color: $whiteColor;
      padding: 0.5rem;
      height: auto;
      left: 1rem;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: $blackColor;
      border-right: none;
      &.show-when-opened {
        z-index: 5;
      }
    }

    &:hover {
      cursor: pointer;
      border: 0.1rem solid $primaryColor;
    }
  }

  .dropdown-opened {
    display: flex;
    flex-direction: column;
    background-color: $whiteColor;
    z-index: 3;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    border-radius: 0.4rem;
    border: 0.1rem solid $primaryColor;
    &.dropdown-upside-down {
      top: auto;
      bottom: 0;
      flex-direction: column-reverse;
      .search-dropdown-box {
        border-top: 0.1rem solid $paleBlueColor;
      }
      ul {
        border-radius: 0.4rem 0.4rem 0 0;
      }
    }
    .dropdown-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 3.9rem;
      border-bottom: 0.1rem solid $paleBlueColor;
      border-radius: 0.4rem 0.4rem 0 0;
      p {
        border-right: 1px solid $paleBlueColor;

        padding: 1.2rem 0;
        padding-left: 1rem;
        height: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: $blackColor;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .image-box-dropdown {
        width: 1.9rem;
        height: 1.1rem;
        display: flex;
        align-items: center;
        margin: 0rem 1rem;
        background: url('@/assets/icons/dropdownIcons.svg') -0 -0rem;
      }
    }
    .search-dropdown-box {
      padding: 1rem;
      border-bottom: 0.1rem solid $paleBlueColor;
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 40vh;
      overflow-y: auto;
      padding: 0 1rem;
      .add-new {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: $primaryColor;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        height: 3.9rem;
        border-bottom: 0.1rem solid $lightGreyColor;
        &::before {
          content: '';
          display: inline-block;
          width: 1.9rem;
          height: 1.9rem;
          background: url('@/assets/icons/addSeriesModelTree.svg') 0 0;
          background-repeat: no-repeat;
          margin-right: 1.5rem;
        }
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
      .create-new-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        height: 5.9rem;
        border-bottom: 0.1rem solid $lightGreyColor;
        column-gap: 1rem;
        .input-box {
          width: 100%;
          position: relative;
          .input-text {
            font-weight: 600;
            font-size: 10px;
            line-height: 100%;
            text-transform: uppercase;
            color: $whiteColor;
            background: $primaryColor;
            border-radius: 44px;
            padding: 0.5rem 1.5rem 0.4rem;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            &:hover {
              opacity: 0.7;
              cursor: pointer;
            }
          }
        }
        img {
          width: 1.6rem;
          height: 1.6rem;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
      li {
        height: 3.9rem;
        border-bottom: 0.1rem solid $lightGreyColor;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        &:last-child {
          border-bottom: none;
        }

        input[type='radio'] + label::before {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          flex-shrink: 0;
          background-size: 19px;
          height: 19px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          cursor: pointer;
          background: url('@/assets/icons/dataCaptureRadios.svg') 0 0;
          background-repeat: no-repeat;
        }

        input[type='radio']:checked + label::before {
          background: url('@/assets/icons/dataCaptureRadios.svg') 0 -19px;
          background-repeat: no-repeat;
        }

        input[type='radio'] + label {
          font-size: 14px;
          line-height: 130%;
          color: $blackColor;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          height: 100%;
          padding: 1.1rem 0;
          padding-left: 3.4rem;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
        .no-results {
          font-size: 14px;
          line-height: 130%;
          color: $blackColor;
        }
      }
    }
  }
}
