.dropdown {
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  position: relative;
  width: 100%;

  &:focus {
    outline: none;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }
  &.isInvalid {
    .dropdown-outside {
      border: 0.1rem solid $redColor;
      .imageContainer {
        border-left: 0.1rem solid $redColor;
      }
      p {
        color: $redColor;
      }
    }
    .dropdown-opened {
      .dropdown-title {
        p {
          color: $redColor;
        }
      }
    }
  }

  .dropdown-outside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid $paleBlueColor;
    border-radius: 0.4rem;
    width: 100%;
    height: 3.9rem;
    position: relative;
    .imageContainer {
      display: flex;
      align-items: center;
      padding: 0.7rem 1rem;
      border-left: 0.1rem solid $paleBlueColor;
      height: 100%;
      .image-box-dropdown {
        display: block;
        width: 1.9rem;
        height: 1.1rem;
        background: url('@/assets/icons/dropdownIcons.svg') -0 -1.1rem;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $paleBlueColor;
      padding-top: 0.9rem;
      padding-left: 1rem;
      height: 100%;
      &.normalColor {
        color: $blackColor;
      }
    }
    .brand-text {
      height: auto;
      position: absolute;
      top: -1rem;
      left: 1rem;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: $blackColor;
      padding: 0.5rem;
      &.zIndex {
        z-index: 5;
      }
      background-color: $whiteColor;
    }

    &:hover {
      cursor: pointer;
      border: 0.1rem solid $primaryColor;
      .imageContainer {
        border-left: 0.1rem solid $primaryColor;
      }
    }
  }

  .dropdown-opened {
    display: flex;
    flex-direction: column;
    background-color: $whiteColor;
    z-index: 3;
    overflow: hidden;
    border: 0.1rem solid $paleBlueColor;
    border-radius: 0.4rem;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .dropdown-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 0.1rem solid $paleBlueColor;
      height: 3.9rem;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: $blackColor;
        padding: 1rem;
        width: 22.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .imageContainer {
        display: flex;
        align-items: center;
        padding: 0.7rem 1rem;
        border-left: 0.1rem solid $paleBlueColor;
        height: 100%;
        .image-box-dropdown {
          display: block;
          width: 1.9rem;
          height: 1.1rem;
          background: url('@/assets/icons/dropdownIcons.svg') -0 -0rem;
        }
      }
    }
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 40vh;
      overflow-y: auto;
      li {
        height: 4rem;
        border-bottom: 0.1rem solid $lightGreyColor;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 1rem;

        &.highlighted {
          background-color: #f5f5f5;
        }

        &:last-child {
          border-bottom: none;
        }

        label {
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.03em;
          color: $blackColor;
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 1.25rem 0px;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
