.second-navigation {
  .second-navigation-box {
    position: relative;

    &.statistics-button-box {
      height: 40px;
      justify-content: flex-end;
    }
    .statistics-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      background-color: $primaryColor;
      border-radius: 4.4rem;
      height: 39px;
      gap: 10px;
      cursor: pointer;
      flex-shrink: 0;

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: $whiteColor;
        text-transform: uppercase;
        padding-top: 2px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
    .statistics-button-active {
      background: $whiteColor;
      border: 1px solid #d1d1d1;
      box-shadow: 0px 0px 25px rgba(26, 44, 81, 0.3);
      border-radius: 19px;
      display: flex;
      width: 307px;
      position: absolute;
      top: 0px;
      right: 0px;

      .statistics-button-active-top-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        cursor: pointer;
        width: 54px;
      }
      .close-search-box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        width: 51px;
        cursor: pointer;
      }
      .statistics-button-active-bottom {
        padding: 10px;
        border-left: 1px solid #d1d1d1;
        border-right: 1px solid #d1d1d1;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-transform: uppercase;
        color: $primaryColor;
        text-align: center;
      }
    }
  }
}

.statistics-container {
  background-color: $veryLightGreyColor;
  width: 100%;
  min-height: calc(100vh - 13.9rem);
  height: auto;
  overflow: hidden;
  padding: 2rem 4rem;

  padding-bottom: 8rem;

  .group-statistics-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0px auto;
    margin-bottom: 20px;
    gap: 10px;
    height: 39px;
    cursor: pointer;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: $primaryColor;
      text-transform: uppercase;
    }
    &.group-statistics-button-active {
      width: 100%;
      background: $whiteColor;
      border: 1px solid #d1d1d1;
      box-shadow: 0px 0px 25px rgba(26, 44, 81, 0.3);
      border-radius: 20px;
      max-width: 320px;
      gap: 0;
      .group-statistics-button-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 51px;
        height: 100%;
      }
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-right: 1px solid #d1d1d1;
        height: 100%;
        border-left: 1px solid #d1d1d1;
        cursor: default;
      }
    }
  }

  .statistics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    .statistics-header {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background-color: $whiteColor;
      border-radius: 0.8rem;
      transition: all 0.2s ease-in-out;

      &.selectedStatistic {
        background-color: $primaryColor;
        .statistics-name {
          .form-name {
            color: $whiteColor;
          }
          &:hover {
            background-color: $primaryColor;
            .form-name {
              color: $tableHover;
            }
          }
        }
      }

      &.selectedAndHasData {
        border-radius: 0.8rem 0.8rem 0 0;
      }

      .statistics-name {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem;
        border-radius: 0.8rem;
        .form-name {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: $primaryColor;
          padding-left: 2rem;
        }
        &:hover {
          cursor: pointer;
          background-color: $tableHover;
          img {
            opacity: 0.7;
          }
        }
      }
      .form-information {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem 2rem;
        &.no-data-container {
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          .no-data {
            margin-top: 2rem;
          }
        }
        .no-data {
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: $whiteColor;
        }
        .information {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .information-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: 6rem;

            &:last-child {
              margin-right: 0;
            }
            .information-title {
              font-weight: 400;
              font-size: 14px;
              line-height: 130%;
              color: $whiteColor;
              padding-bottom: 0.5rem;
            }
            .information-value {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: $whiteColor;
            }
          }
        }
        .download {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: 2rem;
          border-left: 0.2rem solid $whiteColor;
          .download-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: $whiteColor;
            margin-bottom: 0.5rem;
          }
          .download-options {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .download-option {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background: transparent;
              cursor: pointer;
              outline: none;
              border: 0 none;
              p {
                font-weight: 600;
                font-size: 14px;
                line-height: 100%;
                color: $whiteColor;
                margin-left: 0.5rem;
                padding-top: 0.2rem;
              }
            }
          }
        }
      }
    }
    .statistics-graphs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      background-color: $whiteColor;
      .graph-box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        .daily-statistics {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 2rem 0;
          .daily-button {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            background-color: $primaryColor;
            padding: 1rem 1.5rem 1rem 2rem;

            border-radius: 4.4rem;
            p {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: $whiteColor;
              text-transform: uppercase;
              margin-right: 2rem;
            }

            &.hover-able-daily-button {
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
            }
          }
        }
        &.threeGraphs {
          .graph {
            width: calc(100% / 3);
          }
        }
        .graph {
          width: 50%;
          &.line-graph {
            height: auto;
            width: 100%;
            canvas {
              padding: 2rem;
            }
          }
          &.customersGraph {
            display: none;
          }
        }
      }
    }
  }
}

@include respond(tablet) {
  .second-navigation {
    .second-navigation-box {
      .statistics-button,
      .statistics-button-active {
        display: none;
      }
    }
  }
  .statistics-container {
    padding: 2rem;
    padding-bottom: 8rem;
    .group-statistics-button-active {
      display: none;
    }
    .statistics {
      .statistics-header {
        .form-information {
          flex-direction: column;
          align-items: flex-start;
          .download {
            border-left: 0 none;
            padding-left: 0;
            padding-top: 2rem;
            border-top: 0.2rem solid $whiteColor;
            width: 100%;
            margin-top: 2rem;
          }
        }
      }
      .statistics-graphs {
        .graph-box {
          &.threeGraphs {
            .graph {
              width: 50%;
            }
          }
          .graph {
            &.line-graph {
              display: none;
            }
            &.customersGraph {
              display: flex;
            }
          }
        }
      }
    }
  }
}
@include respond(phone) {
  .statistics-container {
    .statistics {
      .statistics-header {
        .statistics-name {
          .form-name {
            font-size: 17px;
          }
          &:hover {
            background-color: $whiteColor;
            img {
              opacity: 1;
            }
          }
        }

        &.selectedStatistic {
          .statistics-name {
            &:hover {
              .form-name {
                color: $whiteColor;
              }
            }
          }
        }
        .form-information {
          .information {
            flex-direction: column;
            align-items: flex-start;
            .information-box {
              margin-right: 0;
              margin-bottom: 2rem;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .statistics-graphs {
        .graph-box {
          flex-direction: column;
          &.threeGraphs {
            .graph {
              width: 100%;
            }
          }
          .graph {
            width: 100%;
            border-right: 0 none;
            overflow: hidden;
            max-height: 36.7rem;
          }
        }
      }
    }
  }
}
